<template>
  <div>
    <header>
      <HeaderNavHomepage />
      <main>
        <div class="main-page">
          <div class="left-part">
            <h1>
              <span class="title-design">Merch by HelixPay</span>
            </h1>
            <p class="left-para">
              Design, sell and ship merch to your fans & community.
            </p>
            <div class="cta-btn">
              <a :href="SIGN_UP_URL" class="signup"> Sign-up for Free </a>
              <!-- <a
                href="https://dashboard.meetbit.io/meeting/HelixPay"
                class="schedule"
              >
                Schedule Demo
              </a> -->
            </div>
          </div>
        </div>
      </main>
    </header>

    <section>
      <div class="we-handle">
        <p>
          We handle everything from design & production to storage & deliveries.
        </p>
        <i class="fa-solid fa-arrow-right"></i>
        <p>Earn big revenues directly from your fans.</p>
      </div>
    </section>

    <ProductCatalogue />
    <MerchHover />
    <MerchProdWhse />
    <MerchPartners />
    <MerchFulfillment />
    <CCLaunchSubscriptions />
    <MainFooter />
  </div>
</template>

<script setup>
import CCLaunchSubscriptions from '@/components/CCLaunchSubscriptions.vue';
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import MerchFulfillment from '@/components/MerchFulfillment.vue';
import MerchHover from '@/components/MerchHover.vue';
import MerchPartners from '@/components/MerchPartners.vue';
import MerchProdWhse from '@/components/MerchProdWhse.vue';
import ProductCatalogue from '@/components/ProductCatalogue.vue';
import { SIGN_UP_URL } from '@/constants';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

useHead(
  buildPageMeta({
    title: 'Merch | Ecommerce Technology for Creators & Communities',
    description:
      'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
    url: 'https://www.helixpay.ph/merch',
    image: 'https://www.helixpay.ph/img/hxp-merch-meta.png',
  })
);
</script>

<style scoped>
main {
  width: 100%;
  position: relative;
  overflow: hidden !important;

  background-image: url('/img/merch-main-img.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-page {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 73rem;
  margin: auto;
  padding: 12rem 2rem;
  align-items: center;
}

.left-part {
  width: 60%;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  align-items: left;
  justify-content: left;
  z-index: 9;
}

.left-part h1 {
  width: 100%;
  font-size: 4rem;
  font-weight: 700;
  color: white;
  line-height: 1.3;
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: left;
}

.left-para {
  color: white;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.cta-btn {
  width: 100%;
  display: flex;
}

.cta-btn a {
  text-align: center;
  margin-top: 10px;
  padding: 1rem;
}

.signup {
  width: 100%;
  background-color: #25a4e1;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.schedule {
  width: 100%;
  background-color: #9affe1;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.signup:hover {
  background-color: #2094ca;
}

.schedule:hover {
  background-color: #74e2c1;
}

/* SECOND SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ WE HANDLE */

section {
  width: 100%;
}

.we-handle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  align-items: center;
  max-width: 71rem;
  margin: auto;
  padding: 7rem 2rem;
}

.we-handle p {
  font-size: 1.5rem;
  font-weight: 700;
}

.we-handle i {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
}

.creative {
  color: white;
  background-color: black;
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1050px) {
  .left-part {
    width: 100%;
  }

  .left-part h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .left-para {
    text-align: center;
  }

  .we-handle {
    grid-template-columns: 1fr;
    gap: 1rem;
    text-align: center;
    padding: 3rem 2rem;
  }

  .cta-btn {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 650px) {
  main {
    background-position: 80% 20%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .cta-btn {
    width: 60%;
    display: grid;
    margin: auto;
    margin-top: 2rem;
  }

  .main-page {
    height: 90vh;
    grid-template-columns: 1fr;
    gap: 3rem;
    position: inherit;
    gap: 0;
    padding: 0 0;
  }

  .left-part h1 {
    width: 85%;
    margin: auto;
    font-size: 3rem;
  }

  .left-para {
    width: 85%;
    padding-top: 2rem;
    font-size: 1rem;
    margin: auto;
  }

  .we-handle p {
    font-size: 1rem;
  }

  .we-handle i {
    display: none;
  }
}
</style>
